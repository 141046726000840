
 body {background-color: #ffffff !important;}
  #logo {height: 78px !important;width: 230px !important;}
  canvas{
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  .bg-custom {background-color: #e3e3e3;}
  .visually-hidden {display: none;}

h1, h5 {padding-left: 20px;}

.btn {display: inline-block !important;}
.flexrow {display: flex;flex-wrap: wrap;flex-direction: row;}
.flexcolumn {display: flex;flex-wrap: wrap;flex-direction: column;}
#sbeae_enregistrement {display: flex;flex-wrap: wrap;flex-direction: row;}
.form-group {min-width: 300px;}
#enregistrement_ncompteurs {min-height:200px;}
#donnees {width:440px;display: block;float:left;margin-left:310px;min-height: 800px;}
#capture {width:280px !important;display: block;float:left;position:fixed; top:80px;}
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}
/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
h1 {
display: block;
padding: 5px;
background-color: #2fa2db;
font-size: 22px !important;
color: #ffffff !important;
font-weight: bold;
}
.titre {
display: block;
padding: 3px;
background-color: #71c5f0;
font-size: 14px !important;
color: #ffffff !important;
font-weight: bold;
}
/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: green;
}
input:checked + .slider2 {
  background-color: blue !important;
}

input:checked + .slider3 {
  background-color: red !important;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}
.invalide {
  background-color: #FFC0AC !important;
}

.tooltip2
{
  text-decoration:none;
  position:relative;
}


.tooltip2 span
{
  display:none;
  -moz-border-radius:6px;
  -webkit-border-radius:6px;
  border-radius:6px;
  color:black;
  background:white;
}


.tooltip2 span img
{
  float:left;
  margin:0px 8px 8px 0;
}


.tooltip2:hover span
{
  display:block;
  position:absolute;
  top:0;
  left:-340px;
  z-index:1000;
  width:auto;
  max-width:520px;
  min-height:128px;
  border:1px solid black;
  margin-top:12px;
  margin-left:32px;
  overflow:hidden;
  padding:8px;
}

    .btnp {
            display: block;
            background-color: #2fa2db;
            color: #FFFFFF !important;
            padding: 7px 10px;
            text-transform: uppercase;
            font-weight: bold;
            border: none;
            border-radius: 0px !important;
            -webkit-transition: all 0.5s ease-in-out;
            -moz-transition: all 0.5s ease-in-out;
            -o-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
            font-family: Helvetica;
            font-size: 14px;
            font-weight: 100;
            margin: 0px;float: left;
        }
        .btnp a {
            color: #fff;
            text-decoration: none;
        }
        a.btnp {
            color: #fff;
            text-decoration: none;
            display: inline-block;
        }
    .btnvehicule {background-color: #34c0c8 !important;}   
    .flexcolumn {
      display: flex;
      flex-direction: column;
    }
    .boutonjour {
      width: auto;
      margin-bottom: 10px;
      text-align: center;
    }
    .blanc {
      color: #fff;
    }
    a.blanc {
      color: #fff;
    }
    .fenetre-centered {padding: 20px;}
   

    .dateur {
      width: 300px !important;height: 45px;display : block;
    }
    div.sr-only {

        }